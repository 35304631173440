<h2 mat-dialog-title class="bold">Bienvenue sur la nouvelle version de LINEO</h2>
<mat-dialog-content class="mat-typography text-justify">
  <p class="bold">LINEO - Version La Thur</p>


  <h3 class="uppercased" style="margin: 0 0 0px;">PAGE DEMANDE ET FORMULAIRE DE CREATION</h3>
  <ul style="margin-bottom: 0.5rem; text-align: justify;">
    <li style="text-align: justify;">Ajout d'un bouton d'accès cartographique qui ouvre ATLAS à l'emplacement de le demande et permet de superposer avec d'autres couches (OI, patrimoine, abonnés,...)</li>
  </ul>

  <br>
  <h3>Pour plus de détails, voir la <a style="text-decoration: underline;" aria-label="Fiche mémo" href="{{fiche_memo_link}}" target="_blank">Fiche Mémo</a></h3>

</mat-dialog-content>
<mat-dialog-actions>
  <p class="appVersion">Version {{appVersion}}</p>
  <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial class="uppercased mat-primary">
    Je découvre
  </button>
</mat-dialog-actions>
