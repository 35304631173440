<div class="table-header">
  <div class="table-icons">
    <button
      mat-icon-button
      matTooltip="Cartographie"
      aria-label="Cartographie"
      class="clickable "
      (click)="openMap()"
      *ngIf="toggle_feature_map_on_demands && showGoogleMap"
    >
      <mat-icon class="map-icon">map</mat-icon>
    </button>
    <button
      matTooltip="Réinitialiser les filtres et tris"
      aria-label="Réinitialiser les filtres et tris"
      class="clickable button-image"
      (click)="resetFilters()"
    >
      <img class="filter-icon" src="/assets/filter_not.png" alt="" width="22" height="22"/>
    </button >
  </div>
  <div class="search">
    <form>
      <mat-form-field class="search-input">
        <mat-label>
          Rechercher par mots-clés
        </mat-label>
        <input matInput [formControl]="searchQuery" autocomplete="off" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </form>
  </div>
</div>

<div class="chips-div">
  <mat-chip-listbox *ngIf="isColumnsFiltered" aria-label="filtres selectionnés">
    <mat-chip-option
      *ngFor="let col of columns"
      [class.hideChip]="!col.isFiltered"
      class="chip"
      [selectable]="false"
      [removable]="true"
      (removed)="removeFilter(col)"
      (dblclick)="openFilter($event, col, false)"
    >
    <div class="inline">
      <div class="chip-name">{{col.label}} :</div>
      <div class="chip-value">&nbsp;{{ getChipValue(col) }}</div>
    </div>
      <button matChipRemove>
        <span class="visually-hidden">Supprimer le filtre {{col.label}}</span>
        <mat-icon class="close">cancel</mat-icon>
      </button>
    </mat-chip-option>
  </mat-chip-listbox>
</div>
<mat-progress-bar mode="query" class="loading-bar" *ngIf="isLoading"></mat-progress-bar>
<div class="table-container" [class.hasFilter]="hasFilter" [class.blur]="isLoading" *ngIf="!isLoading">
  <table matSort mat-table [dataSource]="dataSource" class="table" (matSortChange)="onSort($event)">
    <ng-container [matColumnDef]="col.key" *ngFor="let col of columns" [sticky]="col.sticky"
      [stickyEnd]="col.stickyEnd">
      <th class="th-header" mat-header-cell *matHeaderCellDef [ngClass]="{
          'column-num': col.key === 'id',
          'actions-column': col.key === 'action'
        }">
        <div class="header-div" >
          <div [mat-sort-header]="col.key" [disabled]="col.key === 'action'">
            {{col.label}}
          </div>
          <button
            *ngIf="col.filter !== 'none'"
            (click)="openFilter($event, col)"
            [attr.aria-label]="col.label"
            class="btn-filter"
            [class.filter-enable]="col.isFiltered"
          >
            <mat-icon>filter_list</mat-icon>
          </button>
        </div>
      </th>

      <td mat-cell *matCellDef="let itemData" class="cell"
        [ngClass]="{
        'column-num': col.key === 'id',
        'actions-column': col.key === 'action',
        'urgent-demand': col.key == 'realisation_datetime' && isUrgentDemand(itemData) && toggle_feature_display_urgence_in_red,
        'high-impact': col.key == 'observed_impact' && isHighImpact(itemData)
        }"
      >
        <ng-container [ngSwitch]="col.key">

          <ng-container *ngSwitchCase="'action'">
            <span>
              <button
                class="clickable btn-action"
                (click)="doActionOne(itemData)"
                aria-label="Detail de notification"
              >
                <mat-icon  class="action-icon">{{ actionOneIcon }}</mat-icon>
              </button>
            </span>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <span
              *ngIf="col.type !== 'date' && col.type !== 'link' && col.type !== 'minutesDuration' && col.type !== 'attach_link'"
              [ngClass]="col.key === 'id' ? 'cell-status' : ''"
              [ngStyle]="{ '--statusColor': col.key === 'id' ? setIdColor(itemData) : 'inherit' }"
              [style.white-space]="col.whiteSpace ? col.whiteSpace : 'inherit'">
              {{ itemData[col.key] }}
            </span>
            <span *ngIf="col.type === 'date'"
              [style.white-space]="col.whiteSpace ? col.whiteSpace : 'inherit'">
              {{ itemData[col.key] | date: 'dd/MM/yy HH:mm' }}
            </span>
            <span *ngIf="col.type === 'link'" [style.white-space]="col.whiteSpace ? col.whiteSpace : 'inherit'">
              <a (click)="linkClick(col, itemData)" href="#">{{ itemData[col.key] }}</a>
            </span>
            <span *ngIf="col.type === 'minutesDuration'"
              [style.white-space]="col.whiteSpace ? col.whiteSpace : 'inherit'">
              {{ itemData[col.key] > 0 ? 'Durée restante : ' : 'Retard de : ' }}
              {{ itemData[col.key] | minutesDuration }}
            </span>
            <span *ngIf="col.type === 'attach_link'" [style.white-space]="col.whiteSpace ? col.whiteSpace : 'inherit'">
              <mat-icon *ngIf="itemData['link'] != null" (click)="goToLink(itemData['link'])" style="margin-right: 5px;"
                class="link-icon">link</mat-icon>
              {{ itemData[col.key] }}
            </span>
          </ng-container>

        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row mat-row *matRowDef="let row; columns: displayedColumns;let i = index" (dblclick)="rowDoubleClick(row)"
      class="clickable"></tr>
  </table>
</div>
<div *ngIf="isLoading" style="height: 83.5%;"></div>
<div [class.table-footer]="!isSmallScreen" [class.table-footer-mobile]="isSmallScreen">
  <mat-paginator showFirstLastButtons [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" [hidePageSize]="isSmallScreen">
  </mat-paginator>

  <div class="table-footer-mobile-actions">
    <button mat-stroked-button class="uppercased csv-button mat-primary"
      [hv360-loading]="pendingExport" (click)="exportCSV()" *ngIf="showExportButton && !indicatorUrl">
      Export complet
    </button>

    <button mat-stroked-button class="uppercased csv-button mat-primary"
      [hv360-loading]="pendingExport" (click)="exportCSV()" *ngIf="showExportButton && indicatorUrl">
      Export simple
    </button>

    <button mat-raised-button class="uppercased mat-primary" (click)="close()" *ngIf="showClose">
      Fermer
    </button>
  </div>
</div>
