<h3 mat-dialog-title>Participant(s)</h3>
<div mat-dialog-content>
  <div class="modal-wrapper">
    <div class="row">
      <span class="label-form">
        Référent <span style="color:red;">*</span>
        <mat-icon matTooltip="Le référent, obligatoire, est l'unique interlocuteur du demandeur sur la demande.
                Veuillez le modifier avec modération.">
          info</mat-icon>
      </span>
      <mat-form-field class="full-width">
        <input matInput placeholder="Veuillez saisir une adresse mail" aria-label="Référent" autocomplete="off"
          [formControl]="responsableForm" [matAutocomplete]="autoResp">
        <mat-autocomplete #autoResp="matAutocomplete">
          <mat-option *ngFor="let option of filteredResponsables$ | async" [value]="option.email">
            {{option.email}}
          </mat-option>
        </mat-autocomplete>
        <mat-progress-bar class="autocomplete-progress" *ngIf="loading" mode="indeterminate">
        </mat-progress-bar>
      </mat-form-field>
    </div>


    <div class="row">
      <span class="label-form">
        Intervenant(s)
      </span>
      <mat-form-field class="full-width">
        <input matInput #intervenantInput autocomplete="off"
          placeholder="Veuillez saisir une ou plusieurs adresses mail" aria-label="Intervenants"
          [formControl]="intervenantsForm" [matAutocomplete]="autoInter">
        <mat-autocomplete #autoInter="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let option of filteredIntervenants$ | async" [value]="option">
            {{option.email}}
          </mat-option>
        </mat-autocomplete>
        <mat-progress-bar class="autocomplete-progress" *ngIf="loading" mode="indeterminate">
        </mat-progress-bar>
      </mat-form-field>
    </div>

    <div class="row" style="padding-bottom: 20px">
      <mat-chip-listbox #chipList>
        <mat-chip-option *ngFor="let intervenant of selectedIntervenants" [selectable]="false" [removable]="true"
          (removed)="remove(intervenant)">
          <span [ngClass]="{'text-red': intervenant.suspended === true}" matTooltip="Utilisateur suspendu"
            [matTooltipDisabled]="intervenant.suspended === false">
            {{ intervenant?.email }}
          </span>
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      </mat-chip-listbox>
    </div>

    <div class="row" *ngIf="toggle_feature_add_comment_to_assignation">
      <span class="label-form">
        Commentaire
      </span>
      <div class="rgpd-block">
        <mat-icon class="rgpd-icon rgpd-icon-spacing">lock</mat-icon>
        <i class="rgpd-info rgpd-info-first">
          Évitez les informations nominatives et/ou personnelles
        </i>
      </div>
      <mat-form-field class="full-width">
        <textarea matInput #commentInput placeholder="{{ messagePlaceholder }}" [attr.maxlength]="messageMaxLength"></textarea>
        <mat-hint align="end">
          <span class="visually-hidden">Compteur de caractères</span>
          {{commentInput?.value.length || 0}}
          <span aria-hidden="true">/</span><span class="visually-hidden">sur</span>
          {{ messageMaxLength }}
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="margin:15px">
  <button mat-button class="uppercased mat-primary" (click)="close()">ANNULER</button>
  <button mat-button class="uppercased mat-primary" (click)="update()" [hv360-loading]="btnLoading"
    [disabled]="responsableForm.invalid">METTRE À JOUR</button>
</div>
