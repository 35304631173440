<ng-template #footerButtons>
  <div id="skiplink-demand-action" aria-label="Actions demande" class="center-button">
    <app-demand-pdf-btn [defaultStyle]="false"></app-demand-pdf-btn>

    <div class="btn-wrapper">
      <button mat-stroked-button *ngIf="toggle_feature_carto_atlas" class="demand-action-btn uppercased mat-primary hide-mobile"
        (click)="goToAtlas()">
        <mat-icon class="material-icons">map</mat-icon>
        Accès cartographie
      </button>
    </div>

    <div class="btn-wrapper" *ngIf="demand.attachments?.length > 0 || paGotAttachment" >
      <button mat-stroked-button class="demand-action-btn download-all-btn mat-primary" (click)="allDownload()">
        <mat-icon>attach_file</mat-icon> TOUT TÉLÉCHARGER
      </button>
    </div>
    <div *ngIf="hasPermission() && isAddAttachementEnable()">
      <app-demand-upload-btn (reloadHistory)="reloadHistory.emit(true)" [demand]="demand"></app-demand-upload-btn>
    </div>

    <div class="btn-wrapper" *ngIf="hasPermission() && isAddAttachementEnable()">
      <button (click)="addUrl()" mat-stroked-button class="uppercased demand-action-btn mat-primary">
        <mat-icon class="mat-18" style="margin-bottom: 5px;">link</mat-icon>
        Ajouter un lien
      </button>
    </div>
    <div *ngIf="hasPermission()">
      <app-btn-apporter-precisions class="btn-transition-outlined" [demand]="demand"></app-btn-apporter-precisions>
      <app-btn-abandon-demand [demand]="demand" *ngIf="hasPermission()"></app-btn-abandon-demand>
      <app-btn-envoyer-demand [demand]="demand" *ngIf="hasPermission()"></app-btn-envoyer-demand>
      <app-btn-rejeter-cloture [demand]="demand" *ngIf="hasPermission()"></app-btn-rejeter-cloture>
      <app-btn-demander-point-avancement [demand]="demand" *ngIf="hasPermission()"></app-btn-demander-point-avancement>
      <app-btn-accepter-cloture class="btn-transition-outlined" [demand]="demand" *ngIf="hasPermission()"></app-btn-accepter-cloture>
      <app-btn-rejeter-abandon [demand]="demand" *ngIf="hasPermission()"></app-btn-rejeter-abandon>
    </div>
    
   
    <div class="btn-wrapper" *ngIf="isUserAuthorizedForReopenDemand && demand?.workflow_current_state.code === 'DEMANDE_CLOTUREE'">
      <button mat-stroked-button *ngIf="!isReopenRequested" (click)="openDialog();" class="demand-action-btn uppercased mat-primary"
        [ngClass]="defaultStyle ? 'demand-action-btn-pdf-default' : 'demand-action-btn-pdf'">
        <mat-icon>replay</mat-icon>Réouvrir la demande
      </button>
    </div>
    
    <div *ngIf="hasPermission()">
      <app-btn-accepter-abandon [demand]="demand" class="btn-transition-outlined"></app-btn-accepter-abandon>
    </div>

  </div>
</ng-template>

<main class="main-view">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="progressbar"></mat-progress-bar>
  <app-lineo-breadcrumb [childPageName]="'Consultation demande'"></app-lineo-breadcrumb>
  <div id="main-content">
    <h2 class="header-demand-form">
      <span>Consultation demande</span>
      <div class="header-next-prev">
        <button aria-label="Demande précédente" mat-button (click)="goToPreviousDemand()" [disabled]="!previousDemand"
          *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand)" style="font-size: 30px;"
          class="mat-primary chevron">
          &lt;
        </button>
        <button aria-label="Demande suivante" mat-button (click)="goToNextDemand()" [disabled]="!nextDemand"
          *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand)" style="font-size: 30px;"
          class="mat-primary chevron">
          &gt;
        </button>
      </div>
    </h2>
    <app-demand-page-header [demand]="demand"></app-demand-page-header>
    <app-demand-page-content [demand]="demand"></app-demand-page-content>
  </div>
</main>

<footer class="dmd-status demand-footer hide-mobile">
  <div style="display: flex;">
    <span style="min-width: 270px;"><app-demand-status-info [demand]="demand"></app-demand-status-info></span>
    <div class="dmd-status-btn-wrap--demandeur">
      <ng-content *ngTemplateOutlet="footerButtons"></ng-content>
    </div>
  </div>
</footer>
<!-- Mobile / Tablet footer -->
<mat-accordion class="footer-accordion hide-desktop">
  <mat-expansion-panel [disabled]="false">
    <mat-expansion-panel-header>
      <app-demand-status-info [demand]="demand" [showDate]="true">
      </app-demand-status-info>
    </mat-expansion-panel-header>
    <div class="footer-body" [ngStyle]="{'margin-top': isSmallScreen ? '35px' : '0'}">
      <ng-content *ngTemplateOutlet="footerButtons"></ng-content>
    </div>
  </mat-expansion-panel>
</mat-accordion>