import { Component, OnInit, Input, OnDestroy, SimpleChanges, EventEmitter, ViewChild, Output } from '@angular/core';
import { first, clone, flatten, debounce } from 'lodash';
import { Demand } from 'src/app/shared/models/demand';
import { User, UserType } from 'src/app/shared/models/user';
import { environment } from '@env/environment';
import {
  trigger,
  transition,
  state,
  style,
  animate,
} from '@angular/animations';
import { getUserMode, userIsDemandeur, userIsExploitant } from '@app/shared/helpers/user-modes-helper';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { Observable, Subscription } from 'rxjs';
import { IAttachment } from '@app/shared/models/attachment';
import { IAssignation } from '@app/shared/models/assignations';
import * as _ from 'lodash';
import { demandMatchState, getDemandStatusLabel } from '@app/shared/helpers/demand-helper';
import * as moment from 'moment';
import { NavigationStart, Router, ActivatedRoute } from '@angular/router';
import Toast from '@app/shared/helpers/toast';
import { isAssigned } from '@app/shared/helpers/user-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { IUpdateHistory } from '@app/shared/models/updatehistory';
import translate from '@assets/i18n/fr.json';
import { DatePipe } from '@angular/common';
import { ReviewService } from '@app/shared/services/review/review.service';
interface ICurrentState {
  label: string;
  code: string;
}
interface IMessage {
  user: User;
  message: string;
  created_at: string;
  left?: boolean;
  mode?: UserType;
  attachments: IAttachment[];
  isAssignmentMessage?: boolean,
  isOutlined?: boolean;
  type?: string; // Regular = SMS - AttachedFiles = attach_file - Assignments = person - Updates = edit_note
  field?: string;
}
interface IHistoryPoint {
  current_state?: ICurrentState;
  created_at: string;
  user?: User;
  created_by?: User;
  messages: IMessage[];
  isP?: boolean;
  showMsg?: boolean;
  mode?: string;
  message?: string;
  comment?: string;
  attachments: IAttachment[];
}

@Component({
  selector: 'app-treatment-monitoring',
  templateUrl: './treatment-monitoring.component.html',
  styleUrls: ['./treatment-monitoring.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          display: 'block',
          opacity: 1,
        }),
      ),
      state(
        'closed',
        style({
          opacity: 0,
          display: 'none',
        }),
      ),
      transition('* => *', [animate('0.3s')]),
    ]),
  ],
})
export class TreatmentMonitoringComponent implements OnInit, OnDestroy {
//#region ENV shortcut
  toggle_feature_internal_comment_history: boolean = environment.toggle_feature_internal_comment_history;
  toggle_feature_add_internal_comment_history: boolean = environment.toggle_feature_add_internal_comment_history;
  toggle_feature_internal_comment = environment.toggle_feature_internal_comment;
  toggle_feature_internal_reference = environment.toggle_feature_internal_reference;
  toggle_feature_customer_complaint = environment.toggle_feature_customer_complaint;
  toggle_feature_review = environment.toggle_feature_review;
  client_name: string = environment.client_name;
//#endregion

  @Input() demand: Demand;
  @Input() showEveryMessages: boolean = false;
  @Output() reloadHistory: EventEmitter<any> = new EventEmitter<any>();
  @Input() endReload: Observable<any>;

  
  public userMode = getUserMode();
  history: Array<IHistoryPoint | any>;
  private tempMsgToOpen: Array<IHistoryPoint | any> = [];
  subscription: Subscription;
  attachmentNumber: number = 0;
  updateHistoryMessage = translate['demand-update-history'];

  getDemandStatusLabel = getDemandStatusLabel;

  sub: Subscription;
  user: User;

  labelDate1: string;
  labelDate2: string;
  isClosedDemand: boolean = false;
  receiveDateIsEmpty: boolean = true;
  closeDateIsEmpty: boolean = true;
  receiveDateErrMsg: string;
  closeDateErrMsg: string;
  showReceiveDateErrMsg: boolean = false;
  showCloseDateErrMsg: boolean = false;
  memoExpanded: boolean = localStorage.getItem('memoExpanded') != null ||
    localStorage.getItem('memoExpanded') != undefined ? JSON.parse(localStorage.getItem('memoExpanded')) : true;
  reguExpanded: boolean = localStorage.getItem('reguExpanded') != null ||
    localStorage.getItem('reguExpanded') != undefined ? JSON.parse(localStorage.getItem('reguExpanded')) : true;

  @ViewChild('int_ref') internal_reference;
  @ViewChild('receiveDate') receive_date;
  @ViewChild('closeDate') close_date;

  public debounceCustomerComplaintClick: Function; // debounce sur la checkbock réclamation client

//#region Constructor & Angular function
  constructor(
    private demandService: DemandService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private reviewService: ReviewService,
    private datePipe: DatePipe) {
    this.sub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (this.demand) {
          if (this.toggle_feature_internal_reference) {
            this.updateDemandInternalReference();
          }
        }
      }
    });

    this.authService.getUser().then((_user) => {
      this.user = _user as User;
    });

    this.subscription = this.demandService.getDemandUpdated().subscribe((demand) => {
      this.demand = demand;
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.endReload.subscribe((res) => {
      this.tempMsgToOpen = this.history.filter((obj) => obj.showMsg === true);
    })
    this.labelDate1 = "Date réelle de réception de la Demande";
    this.labelDate2 = this.client_name == 'setom' ? "Date réelle de réalisation de la Demande" : "Date réelle de clôture de la Demande";
    this.buildHistory();
    this.fillHistory();
    this.isClosedDemand = this.demand.workflow_current_state.code == "DEMANDE_CLOTUREE" ||
      this.demand.workflow_current_state.code == "DEMANDE_ABANDONNEE" ? true : false;

    this.checkEmptyDate(this.demand.receive_date, this.demand.close_date);
    this.debounceCustomerComplaintClick = debounce(this.updateCustomerComplaintSate, 1000); // init la var
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.demand) {
      this.ngOnInit();
    }
  }

  ngAfterViewInit() {
    if (this.demand.receive_date) this.receive_date.nativeElement.value = moment(this.demand.receive_date).format("DD/MM/YYYY HH:mm")
    if (this.demand.close_date) this.close_date.nativeElement.value = moment(this.demand.close_date).format("DD/MM/YYYY HH:mm")
    if (this.demand.receive_date &&
      !moment(this.extractDate(this.receive_date.nativeElement.value)).isBefore(moment(this.demand.send_date_str))) {
        this.showReceiveDateErrMsg = true;
    }

    if (this.demand.close_date &&
      !moment(this.extractDate(this.close_date.nativeElement.value)).isAfter(moment(this.extractDate(this.receive_date.nativeElement.value)))) {
        this.showCloseDateErrMsg = true;
    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
//#endregion

  public messagesToShow(messages: IMessage[]) {
    if (messages.length === 0) return false
    return true
  }

  /* The number of assignments among a transition */
  public countUpdateHistory: (messages: IMessage[]) => number = _.partialRight(
    this.countMessagesBy,
    (message) => message.isUpdateMessage,
  );
  /* The number of assignments among a transition */
  public countAssignments: (messages: IMessage[]) => number = _.partialRight(
    this.countMessagesBy,
    (message) => message.isAssignmentMessage,
  );
  /* The number of regular messages among a transition */
  /* TODO: Update for file icon */
  public countComments(messages: IMessage[], groupMessages: IMessage[]) {
    return this.countCommentsFromMessages(messages) + this.countCommentsFromGroupMessages(groupMessages);
  }
  private countCommentsFromMessages: (messages: IMessage[]) => number = _.partialRight(
    this.countMessagesBy,
    // Compte le nombre de message qui ne sont pas dans les situations ci dessous. (On les considèrent comme des messages regular)
    (message) => ((!["updates", "attachment", "AttachedFiles"].includes(message.type) && (!message.isAssignmentMessage && !message.isUpdateMessage))),
  );

  private countCommentsFromGroupMessages: (groupMessages: IMessage[]) => number = _.partialRight(
    this.countMessagesBy,
    // Compte les messages des assignations
    (message) => (message.isAssignmentMessage && message.comment != undefined),
  );

  // Generic function to count message by type
  public genericCount (messages: IMessage[], type: string) : number {
    return messages.filter((mes) => (mes.type == type)).length
  }
    
  private countMessagesBy(
    messages: IMessage[],
    filterFn: (messages: IMessage) => boolean,
  ): number {
    return messages
      .filter(filterFn)
      .length;
  }

//#region history functions

  //#region Main functions
  // Créer les transitions (En cours, affectée etc)
  private buildHistory() {
    /* Merge transitions and point d'avancements */
    const transitions = _.cloneDeep(this.demand.transitions);
    if (this.toggle_feature_internal_comment_history) {
      this.history = this.sortByCreatedAt(
        transitions.concat(
          this.demand.point_avancement.map((x) => {
            x.isP = true;
            return x;
          }),
        ),
      );
    } else {
      this.history = this.sortByCreatedAt(
        transitions.concat(
          this.demand.point_avancement.filter((point) => point.type === "PA").map((x) => {
            x.isP = true;
            return x;
          }),
        ),
      );
    }
    /* Add point d'avancements to their status */
    this.history.forEach((point, i, arr) => {
      if (point.isP && (point.type !== 'COM' || (point.type === 'COM' && userIsExploitant()))) {
        let x = 1;
        while (arr[i + x].isP) { x++; }
        arr[i + x].messages.push({
          user: point.created_by,
          message: point.message || 'Point d\'avancement demandé',
          created_at: point.created_at,
          left: point.mode.toLowerCase() == UserType.DEMANDEUR,
          attachments: point.attachments,
          type: point.type,
        });
        this.sortByCreatedAt(arr[i + x].messages);
      }
    });

    /* Remove point d'avancements from history as they are in transition messages now */
    this.cleanHistory((h) => !h.isP);

    // Reopen msg we need to be open after resolver reload
    setTimeout(() => {
      if(this.tempMsgToOpen.length > 0) {
        this.tempMsgToOpen.forEach((obj) => {
          this.history.forEach((his) => {
            if(his.created_at == obj.created_at) {
              his.showMsg = true;
            }
          })
        })
      }
    });
  }

  // Appelle les fontions qui vont ajouter les messages dans chaque transition
  private fillHistory() {
    if (userIsExploitant()) {
      this.addAssignmentsToHistory();
      this.addUpdateToHistory();
      this.addAttachmentsToHistory();
      this.groupMessageByDate();
    } else {
      this.addUpdateToHistory();
      this.addAttachmentsToHistory();
      this.groupMessageByDate();
    }
  }

  private groupMessageByDate() {
    let getDiffDate = ((x, y) => moment.duration(moment(x).diff(moment(y))));
    let newIndex = (x, y) => {
      x.push({
        comment: y.comment,
        date: y.created_at,
        isAssignmentMessage: y.isAssignmentMessage ? true : false,
        isOutlined: y.isOutlined ? true : false,
        left: y.left ? y.left : y.mode ? y.mode.toLowerCase() == UserType.DEMANDEUR : undefined,
        messages: [y],
        user: y.user,
        type: y.type ? y.type : null
      });
    }
    this.history.forEach(hist => {
      let mergedAssign = [];
      hist.messages.forEach(message => {
        if (mergedAssign.length == 0) newIndex(mergedAssign, message);
        else {
          let group = mergedAssign.find(group =>
            getDiffDate(group.date, message.created_at).asMilliseconds() < 5000
          );
          if (group) {
            group.messages.push(message)
          }
          else newIndex(mergedAssign, message);
        }
      });
      hist.groupMessages = this.orderAssignation(mergedAssign);
    });
  }
  
  // Order assignations actions by assigned person
  private orderAssignation(assignations) {
    assignations.forEach(x => {
      if (x && x.isAssignmentMessage) {
        x.messages.forEach((message, index, arr) => {
          let splited = message.message.split(' ');
          if (splited[1] == 'retiré') {
            // If we find another action with the same person, we put this action after the first one
            var matchingIndex = arr.findIndex(mess => mess.message.split(' ')[1] == 'ajouté' && mess.message.split(' ')[2] == splited[2]);
            if (matchingIndex != -1) {
              x.messages.splice(index + 1, 0, x.messages[matchingIndex]);
              x.messages.splice(index < matchingIndex ? matchingIndex + 1 : matchingIndex, 1);
            }
          }
        });
      }
    });

    return assignations;
  }

  private cleanHistory(fn) {
    this.history = this.history.filter(fn);
  }
//#endregion

  //#region Assignment parts

  private addAssignmentsToHistory() {
    let assignationsHistory = _.cloneDeep(this.demand.assignations.history);

    /* Create the original assignment for deleted ones */
    assignationsHistory = flatten(
      assignationsHistory.map((assignment) => {
        if (assignment.deleted) {
          const original_assign = clone(assignment);
          assignment.created_at = assignment.updated_at;
          original_assign.deleted = false;
          return [assignment, original_assign];
        }
        return assignment;
      }));

    this.history = this.sortByCreatedAt(
      this.history.concat(
        assignationsHistory.map((assignment) => {
          assignment.isAssignment = true;
          return assignment;
        }),
      ),
    );

    /* Add assignation into transition message */
    this.history.forEach((point, i, arr) => {
      if (point.isAssignment) {
        let x = 1;
        while (arr[i + x].isAssignment) { x++; }
        arr[i + x].messages.push({
          user: point.deleted ? point.unassigned_by : point.assigned_by,
          message: this.getAssignmentMessage(point),
          comment: this.getAssignmentComment(point),
          created_at: point.created_at,
          isAssignmentMessage: true,
          type: "assignments",
          isOutlined: true,
          left: false,
        });
        this.sortByCreatedAt(arr[i + x].messages);
      }
    });

    /* Remove assignments from history as they are in transition messages now */
    this.cleanHistory((h) => !h.isAssignment);
  }


  private getAssignmentComment(assignment: IAssignation): string {
    if (assignment.comment && !assignment.deleted) return `a écrit : ${assignment.comment}`;
    if (assignment.comment_desa && assignment.deleted) return `a écrit : ${assignment.comment_desa}`;
  }

  private getAssignmentMessage(assignment: IAssignation, deleted?): string {
    deleted = deleted !== undefined ? deleted : assignment.deleted;
    const action = deleted ? 'retiré' : 'ajouté';

    let messageEnd = '';
    if (assignment.assign_type === 'INTERVENANT') {
      const complement = deleted ? 'de' : 'à';
      messageEnd = complement + ' la liste des intervenants';
    } else {
      messageEnd = deleted ? 'du rôle de référent' : 'en tant que référent';
    }
    return `a ${action} ${assignment.user.email} ${messageEnd}`;
  }
  //#endregion

  //#region Update History parts

    private addUpdateToHistory() {
      let updatesHistory = _.cloneDeep(this.demand.updateHistory);

      /* Create the original update for deleted ones */
      updatesHistory = flatten(
        updatesHistory.map((update) => {
          return update;
        }));

      this.history = this.sortByCreatedAt(
        this.history.concat(
          updatesHistory.map((update) => {
            update.isUpdate = true;
            return update;
          }),
        ),
      );

      /* Add update into transition message */
      this.history.forEach((point, i, arr) => {
        // Affiche/Cache internal_comment si demander ou exploitant
        if(point.isUpdate) {
          if(["internal_comment", "internal_comment_link"].includes(point.field) && userIsDemandeur()) {
            return
          }

          let x = 1;
          while (arr[i + x].isUpdate) { x++; }
          arr[i + x].messages.push({
            user: point.user,
            message: this.getUpdateHistoryMessage(point),
            created_at: point.created_at,
            isOutlined: ["internal_comment", "internal_comment_link"].includes(point.field) ? true : false, // Mettre les champs dans la liste en css border
            type: this.getMessageType(point), // Mettre les champs de la liste dans la catégorie regular (sms)
            left: point.mode.toLowerCase() == UserType.DEMANDEUR,
            field: point.field
          });
          this.sortByCreatedAt(arr[i + x].messages);
        }
      });

      /* Remove update from history as they are in transition messages now */
      this.cleanHistory((h) => !h.isUpdate);
    }

    private getMessageType(obj) {
      if(["internal_comment"].includes(obj.field)) return "regular"
      if(["internal_comment_link"].includes(obj.field)) return "attachment"
      return "updates"
    }

    // Traduit le message en fonction du field modifié (table:UpdateHistory)
    private getUpdateHistoryMessage(updateHistory: IUpdateHistory, deleted?): string {
      // check env
      if(this.client_name == "setom") {
        if (['close_date'].includes(updateHistory.field)) {
          return `${this.updateHistoryMessage[updateHistory.field + "_setom"]} :
          ${this.datePipe.transform(updateHistory.original, 'dd/MM/yyyy HH:mm') == null || updateHistory.original == "" ? "'-'" : `'${this.datePipe.transform(updateHistory.original, 'dd/MM/yyyy HH:mm')}'`
            }
          en
          ${this.datePipe.transform(updateHistory.modified, 'dd/MM/yyyy HH:mm') == null || updateHistory.modified == ""  ? "'-'" : `'${this.datePipe.transform(updateHistory.modified, 'dd/MM/yyyy HH:mm')}'`
            }.`;
        }
      }
      // Check if is an attachments
      if (['attachments'].includes(updateHistory.field)) {
        let attachmentsType: string = updateHistory.field
        this.demand.attachments.forEach(obj => {
          if ((obj.file_name == updateHistory.modified) && obj.kind == "URL") {
            attachmentsType = "attachments_url"
          }
          if ((obj.file_name == updateHistory.modified) && obj.kind == "FILE") {
            attachmentsType = "attachments_file"
          }
        })
        return `${this.updateHistoryMessage[attachmentsType]} sous le nom suivant : '${updateHistory.modified}'.`;
      }
      // Check if field is a date
      if (['realisation_datetime', 'close_date', 'receive_date'].includes(updateHistory.field)) {
        return `${this.updateHistoryMessage[updateHistory.field]} :
        ${this.datePipe.transform(updateHistory.original, 'dd/MM/yyyy HH:mm') == null || updateHistory.original == "" ? "'-'" : `'${this.datePipe.transform(updateHistory.original, 'dd/MM/yyyy HH:mm')}'`
          }
        en
        ${this.datePipe.transform(updateHistory.modified, 'dd/MM/yyyy HH:mm') == null || updateHistory.modified == "" ? "'-'" : `'${this.datePipe.transform(updateHistory.modified, 'dd/MM/yyyy HH:mm')}'`
          }.`;
      }
      // Do not show before/after
      if (['description', 'actions_to_go'].includes(updateHistory.field)) {
        return `${this.updateHistoryMessage[updateHistory.field]}.`;
      }
      // check If is boolean
      if(['send_ilotier'].includes(updateHistory.field)) {
        return `${this.updateHistoryMessage[updateHistory.field]} :
        ${updateHistory.original == null || updateHistory.original == "" ? "'-'" : `'${(updateHistory.original == 'False' ? 'non' : 'oui')}'`
          }
        en
        ${updateHistory.modified == null || updateHistory.modified == "" ? "'-'" : `'${(updateHistory.modified == 'False' ? 'non' : 'oui')}'`
          }.`;
      }
      // Reopen
      if (updateHistory.action == "reopen") {   
        
        return this.updateHistoryMessage[updateHistory.action] + updateHistory.modified;
      }

      // Internal comment
      if(['internal_comment'].includes(updateHistory.field)) {
        return `${this.updateHistoryMessage[updateHistory.field]} :  ${updateHistory.modified}`
      }
      // Internal comment
      if(['internal_comment_link'].includes(updateHistory.field)) {
        return `${updateHistory.modified}`
      }

      // Default
      return `${this.updateHistoryMessage[updateHistory.field]} :
      ${
        updateHistory.original == null || updateHistory.original == "" ? "'-'" : `'${updateHistory.original.split('_').join(' ')}'`
      }
        en
      ${
        updateHistory.modified == null || updateHistory.modified == "" ? "'-'" : `'${updateHistory.modified.split('_').join(' ')}'`
      }
      .`;
    }

    // Converti l'url
    public encodeUri(uri) {
      return encodeURI(uri)
    }

    // Obtenir le lien/nom du fichier pour les pièces jointes
    public getInternalCommentLinkInfo(message, type) {
      const content = JSON.parse(message.message)
      return content[type]
    }

  //#endregion

  //#region Attachment parts
  private addAttachmentsToHistory() {
    this.history.forEach((point) => {
      let attachmentNumber: number = 0;
      let pointAttachmentNumber: number = 0;
      let hadAttach: boolean = false;
      point.hadAttachments = {
        bool: false,
        value: 0
      }
      if (point.attachments && point.attachments.length !== 0) {
        hadAttach = true;
        pointAttachmentNumber = pointAttachmentNumber + point.attachments.length;
      }
      if (point.messages && point.messages.length !== 0) {
        point.messages.forEach((msg) => {
          if (msg.attachments && msg.attachments.length !== 0) {
            hadAttach = true;
            attachmentNumber = attachmentNumber + msg.attachments.length;
          }
        });
        point.hadAttachments = {
          bool: hadAttach,
          value: attachmentNumber + pointAttachmentNumber
        }
      }
    });
  }
  //#endregion
  
//#endregion

  public showPointMessages(point: IHistoryPoint): boolean {
    return point.showMsg;
  }

  private sortByCreatedAt(arr) {
    return (
      arr.sort((x, y) =>
        new Date(x.created_at) < new Date(y.created_at) ? 1 : -1,
      )
    );
  }

  public historyMessageRightSide(message: IMessage): boolean {
    return !(message.left ||
      (message.mode && (message.mode.toLowerCase() == UserType.DEMANDEUR)));
  }
  // FIXME:: Either make this available globally,
  // Or use it if it's already defined
  public fullName(point: IHistoryPoint): string {
    const user = point.user || point.created_by;
    if (user.first_name === 'Lineo') {
      user.first_name = 'Action';
      user.last_name = 'automatique';
    }
    return `${user.first_name} ${user.last_name}`;
  }

  public setIdColor(point: IHistoryPoint): string {
    return environment.status_colors[point.current_state.code]
      ? environment.status_colors[point.current_state.code]
      : 'inherit';
  }

  toggleMsg(point: IHistoryPoint): void {
    point.showMsg = !point.showMsg;
  }

  updateDemandInternalReference() {
    if (this.internal_reference
      && this.demand.internal_reference_number !== this.internal_reference.nativeElement.value
      && !(this.demand.internal_reference_number === null && this.internal_reference.nativeElement.value === '')) {

      this.demand.internal_reference_number = this.internal_reference.nativeElement.value;
      this.demandService.updateDemandInternalReference(this.demand.id, this.internal_reference.nativeElement.value).then(() => {
        Toast.info("N° Référence interne mis à jour.");
      })
        .catch((err) => {
          console.log(err);
          Toast.info("Problème lors de la mise à jour du N° référence interne.");
        });
    }
  }

  canChangeInternalFields() {
    return (!demandMatchState(this.demand, [DemandStateCode.CLOTUREE,]) && isAssigned(this.user, this.demand))
      || demandMatchState(this.demand, [DemandStateCode.ENVOYEE]);
  }

  resetDate(num: number) {
    if (num == 1) {
      this.receive_date.nativeElement.value = '';
      this.close_date.nativeElement.value = '';
      this.showReceiveDateErrMsg = false;
      this.showCloseDateErrMsg = false;
      this.updateReceiveDate(true);
    } else {
      this.close_date.nativeElement.value = '';
      this.showCloseDateErrMsg = false;
      this.updateCloseDate();
    }
  }

  extractDate(date: string) {
    let mn = parseInt(date.substring(14, 16));
    let h = parseInt(date.substring(11, 13));
    let year = parseInt(date.substring(6, 10));
    let month = parseInt(date.substring(3, 5)) - 1;
    let day = parseInt(date.substring(0, 2));
    return new Date(year, month, day, h, mn);
  }

  checkEmptyDate(date1: string, date2: string) {
    this.receiveDateIsEmpty = (
      date1 != undefined &&
      date1 != null &&
      date1 != '') ? false : true;
    this.closeDateIsEmpty = (
      date2 != undefined &&
      date2 != null &&
      date2 != '') ? false : true;
  }

  updateReceiveDate(isClearCall:boolean=false) {
    this.checkEmptyDate(this.receive_date.nativeElement.value, this.close_date.nativeElement.value);
    this.showReceiveDateErrMsg = false;
    if (this.receive_date
      && this.demand.receive_date !== this.receive_date.nativeElement.value) {
      this.demandService.updateDemandReceiveDate(this.demand.id, this.receive_date.nativeElement.value, this.user, userIsExploitant).then(() => {
        if ((this.demand.send_date_str != undefined &&
          !moment(this.extractDate(this.receive_date.nativeElement.value)).isBefore(moment(this.demand.send_date_str)))) {
          this.receiveDateErrMsg = "La date réelle de réception doit être antérieure à la date d'envoi";
          if (!this.receiveDateIsEmpty) this.showReceiveDateErrMsg = true;
        } else if (this.receiveDateIsEmpty) {
          this.showReceiveDateErrMsg = false;
        }
        Toast.info("Date réelle de réception mise à jour.");
        if(isClearCall) this.updateCloseDate();
        this.reloadResolve();
      }).catch((err) => {
        console.log(err);
        Toast.info("Problème lors de la mise à jour de la date réelle de réception.");
      });
    }
  }

  updateCloseDate() {
    this.checkEmptyDate(this.receive_date.nativeElement.value, this.close_date.nativeElement.value);
    this.showCloseDateErrMsg = false;
    if (this.close_date
      && this.demand.close_date !== this.close_date.nativeElement.value) {
      this.demandService.updateDemandCloseDate(this.demand.id, this.close_date.nativeElement.value, this.user, userIsExploitant).then(() => {
        if ((this.demand.send_date_str != undefined &&
          !moment(this.extractDate(this.close_date.nativeElement.value)).isAfter(moment(this.extractDate(this.receive_date.nativeElement.value))))) {
          if (this.client_name === 'setom') {
            this.closeDateErrMsg = "La date réelle de réalisation doit être postérieure à la date réelle de réception";
          } else {
            this.closeDateErrMsg = "La date réelle de clôture doit être postérieure à la date réelle de réception";
          }
          if (!this.closeDateIsEmpty)  this.showCloseDateErrMsg = true;
        } else if (this.closeDateIsEmpty) {
          this.showCloseDateErrMsg = false;
        }
        if (this.client_name == 'setom') {
          Toast.info("Date réelle de réalisation mise à jour.");
        } else {
          Toast.info("Date réelle de clôture mise à jour.");
        }
        this.reloadResolve();
      }).catch((err) => {
        console.log(err);
        if (this.client_name == 'setom') {
          Toast.info("Problème lors de la mise à jour de la date réelle de réalisation.");
        } else {
          Toast.info("Problème lors de la mise à jour de la date réelle de clôture.");
        }
      });
    }
  }

  saveExpanded(panel) {
    panel == 1 ? this.memoExpanded = !this.memoExpanded : this.reguExpanded = !this.reguExpanded;
    panel == 1 ? localStorage.setItem("memoExpanded", JSON.stringify(this.memoExpanded)) : localStorage.setItem("reguExpanded", JSON.stringify(this.reguExpanded));
  }

  reloadResolve() {
    this.reloadHistory.emit(true);
  }

  private updateCustomerComplaintSate(event): void {
    if(event && this.demand?.customer_complaint != event['checked'] && this.toggle_feature_customer_complaint) {
      this.demand.customer_complaint = event['checked'];
      this.demandService.updateCustomerComplaint(this.demand.id, event['checked']).then(() => {
        Toast.info("Statut réclamation client mis à jour.");
      })
        .catch((err) => {
          console.log(err);
          Toast.info("Problème lors de la mise à jour du statut réclamation client.");
      });
    }
  }

  public getCustomerComplaintState(): boolean {
    // Si clôture/ abandon
    if(this.demand.workflow_current_state.code === "DEMANDE_CLOTUREE" || this.demand.workflow_current_state.code === "DEMANDE_ABANDONNEE") {
      return true;
    }

    // Si statut envoyée & exploitant
    if(this.demand.workflow_current_state.code === "DEMANDE_ENVOYEE" && userIsExploitant()) {
      return false;
    }

    // Si  intervenants/référent de la demande
    if(this.getAuthorizedUsers().includes(this.user?.email)) {
      return false;
    }

    return true;
  }

  private getAuthorizedUsers(): string[] {
    let emails = []
    emails.push(this.demand?.assignations?.responsable?.user?.email);
    this.demand?.assignations?.intervenants?.forEach(intervenant => {
      emails.push(intervenant?.user?.email)
    })
    return emails;
  }
}