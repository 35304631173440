<main class="main-view">
  <app-lineo-breadcrumb [childPageName]="'Formulaire de demande'"></app-lineo-breadcrumb>
  <div>
    <h2 class="header-demand-form">
      <span>Formulaire de demande
        <span *ngIf="demand?.id"> n° {{ demand.id }} </span>
      </span>
      <div class="header-next-prev">
        <button mat-button (click)="goToPreviousDemand()" [disabled]="!previousDemand"
          *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand) && !editionMode"
          style="font-size: 30px;" class="mat-primary">
          &lt;
        </button>
        <button mat-button (click)="goToNextDemand()" [disabled]="!nextDemand"
          *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand) && !editionMode"
          style="font-size: 30px;" class="mat-primary">
          &gt;
        </button>
      </div>
    </h2>
    <div class="row-no-margin order-flex">
      <div class="col-xs-12 col-lg-9 left-side">
        <mat-card appearance="outlined" class="card">
          <mat-card-content id="skiplink-demand-form" aria-label="Formulaire de demande">
            <app-demand-form *ngIf="!isLoading" [demand]="demand" [user]="user" [beneficiaryTypes]="beneficiaryTypes"
              [emplacementTypes]="emplacementTypes" [activityTypes]="activityTypes" [impactTypes]="impactTypes"
              [ouvrageTypes]="ouvrageTypes" [objectTypes]="objectTypes" [userContracts]="userContracts"
              [contractZones]="contractZones" (saveEvent)="checkDemandForm($event)"
              (deleteEvent)="openDeleteModal(demand)" [events]="formEvents.asObservable()"
              (onMarkerPlaced)="onMarkerPlaced($event)" (selectContract)="handleContractChange($event)">
            </app-demand-form>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-sm-12 col-lg-3 right-side">
        <div class="right-container">
          <button mat-button *ngIf="toggle_feature_carto_atlas" [disabled]="disableCarto" [ngClass]="disableCarto ? 'disable-btn' : 'active-btn'" class="uppercased hide-mobile"
            (click)="goToAtlas()">
            <mat-icon class="material-icons">map</mat-icon>
            Accès cartographie
          </button>
        <app-demand-alert></app-demand-alert>
        </div>     
      </div>
    </div>
  </div>
</main>