<div class="demand-prio-card mixed-round-border">
    <mat-chip-listbox>
        <mat-chip-option *ngIf="demand.has_new_point; else notPA" class="uppercased" selectable="false">
          <span class="circle-icon circle-icon--green"></span>
          <span>{{ getDemandStateLabel() }}</span>
        </mat-chip-option>
        <ng-template #notPA>
            <mat-chip-option class="uppercased" selectable="false">
              <span class="circle-icon" [ngClass]="['dmd-status--demandeur_' + demand.current_state.code.slice(8).toLowerCase()]"></span>
              <span>{{ getDemandStateLabel() }}</span>
            </mat-chip-option>
        </ng-template>
    </mat-chip-listbox>
    <h4>Demande n°{{ demand.id }}</h4>
    <p>
        {{ demand.location_full_address }}
    </p>
    <p class="dmd-card-demandeur">
        Demandeur : {{ demand.created_by.email_contact && demand.created_by.email_contact !== null ? demand.created_by.email_contact : demand.created_by.email }}
    </p>
    <div class="row-no-margin text">
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <span class="dmd-card-date" *ngIf="demand.realisation_datetime">
                Échéance souhaitée : {{ demand.realisation_datetime | date: 'dd/MM/yy'}}
            </span>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 dmd-card-echeance-block">
            <span class="dmd-card-echeance-text" *ngIf="demand.time_left">
                Plus que {{ getTimeLeft(demand.time_left) }} pour statuer
            </span>
        </div>
    </div>
</div>
