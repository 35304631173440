<div class="dmd-status-wrap">
  <div
    class="dmd-status-circle round"
    [ngStyle]="{ 'background-color': setIdColor() }"
  ></div>

  <div class="dmd-status-text-wrap"
       [ngStyle]="{'margin-top': isSmallScreen ? '12px' : '8px'}">
    <h3 class="dmd-status-title bold" style="font-size: 16px; margin-bottom: 10px;">
      {{ getDemandStatusLabel(demand.workflow_current_state.code) }}
    </h3>
    <div class="dmd-status-updated-date" [ngClass]="[getDateCssClass()]" *ngIf="showDate" [ngStyle]="{'position': isSmallScreen ? 'absolute' : 'relative'}"
    tabindex="0" 
    [aria-label]="'Statut mis à jour le ' + getLastTransitionDate | date: 'dd/MM/yyyy' + ', Dernière mise à jour le ' + demand?.updated_at | date: 'dd/MM/yyyy' + '. La date de point de départ pour lauto-clôture est la date de dernière mise à jour.' ">
      Statut mis à jour le {{ getLastTransitionDate | date: "dd/MM/yyyy" }}<br/>
      Dernière mise à jour le {{ demand?.updated_at | date: "dd/MM/yyyy" }}
    </div>
  </div>
</div>
