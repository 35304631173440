<div mat-dialog-title class="title">{{ title }}
  <div class="rgpd-block">
    <mat-icon class="rgpd-icon">lock</mat-icon>
    <i class="rgpd-info rgpd-info-first">
      Évitez les informations nominatives et/ou personnelles
    </i>
  </div>
</div>

<mat-dialog-content>
  <p *ngIf="description">
    {{ description }}
  </p>

  <mat-form-field class="full-width">
    <textarea matInput #input placeholder="{{ messagePlaceholder }}"
              [attr.maxlength]="messageMaxLength"></textarea>
    <mat-hint align="end">
      <span class="visually-hidden">Compteur de caractères</span>
      {{input?.value.length || 0}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span>{{ messageMaxLength }}
    </mat-hint>
  </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions>
    <app-upload
        class="full-width confirm-app-upload"
        *ngIf="showAttachments"
        [files]="attachments"
        [demand]="demand"
        [isFileEnabled]="!hideFileUpload"
        [isLinkEnabled]="!hideLinkUpload"
        [isForInternalComment]="isForInternalComment"
        (filesChanged)="linkAttachments($event)">
    </app-upload>
  <button class="cancel-btn mat-primary" class="uppercased"
      mat-button mat-dialog-close
      (click)="refuse()">
    {{ labelRefuse  }}
  </button>
  <button mat-button class="uppercased mat-primary"
      (click)="confirm()" [disabled]="!input?.value.length && isMandatory">
    {{ labelConfirm }}
  </button>
</mat-dialog-actions>
