import { Component, OnInit } from '@angular/core';

export interface Ipage {
  page: string;
  url: string;
}

export interface IcritereNonConforme {
  crit: string;
  detail: string;
}

@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss']
})

export class AccessibilityComponent implements OnInit {

  public currentUrl : string = window.location.origin + '/';
  public pourcentageConformite : number = 55.2;
  public critereNonConforme : IcritereNonConforme[] = [
    {crit:'Critère 3.1.', detail:' Dans chaque page web, l’information ne doit pas être donnée uniquement par la couleur. Cette règle est-elle respectée ?'},
    {crit:'Critère 3.3.', detail:' Dans chaque page web, les couleurs utilisées dans les composants d’interface ou les éléments graphiques porteurs d’informations sont-elles suffisamment contrastées ?'},
    {crit:'Critère 7.1.', detail:' Chaque script est-il, si nécessaire, compatible avec les technologies d’assistance ?'},
    {crit:'Critère 7.3.', detail:' Chaque script est-il contrôlable par le clavier et par tout dispositif de pointage ?'},
    {crit:'Critère 7.4.', detail:' Pour chaque script qui initie un changement de contexte, l’utilisateur est-il averti ou en a-t-il le contrôle ?'},
    {crit:'Critère 7.5.', detail:' Dans chaque page web, les messages de statut sont-ils correctement restitués par les technologies d’assistance ?'},
    {crit:'Critère 8.7.', detail:' Dans chaque page web, chaque changement de langue est-il indiqué dans le code source ?'},
    {crit:'Critère 8.9.', detail:' Dans chaque page web, les balises ne doivent pas être utilisées uniquement à des fins de présentation. Cette règle est-elle respectée ?'},
    {crit:'Critère 9.1.', detail:' Dans chaque page web, l’information est-elle structurée par l’utilisation appropriée de titres ?'},
    {crit:'Critère 9.2.', detail:' Dans chaque page web, la structure du document est-elle cohérente ?'},
    {crit:'Critère 9.3.', detail:' Dans chaque page web, chaque liste est-elle correctement structurée ?'},
    {crit:'Critère 10.2.', detail:' Dans chaque page web, le contenu visible porteur d’information reste-t-il présent lorsque les feuilles de styles sont désactivées ?'},
    {crit:'Critère 10.3.', detail:' Dans chaque page web, l’information reste-t-elle compréhensible lorsque les feuilles de styles sont désactivées ?'},
    {crit:'Critère 10.7.', detail:' Dans chaque page web, pour chaque élément recevant le focus, la prise de focus est-elle visible ?'},
    {crit:'Critère 10.8.', detail:' Pour chaque page web, les contenus cachés ont-ils vocation à être ignorés par les technologies d’assistance ?'},
    {crit:'Critère 10.9.', detail:' Dans chaque page web, l’information ne doit pas être donnée uniquement par la forme, taille ou position. Cette règle est-elle respectée ?'},
    {crit:'Critère 10.11.', detail:' Pour chaque page web, les contenus peuvent-ils être présentés sans avoir recours à un défilement vertical pour une fenêtre ayant une hauteur de 256px ou à un défilement horizontal pour une fenêtre ayant une largeur de 320px ?'},
    {crit:'Critère 10.12.', detail:' Dans chaque page web, les propriétés d’espacement du texte peuvent-elles être redéfinies par l’utilisateur sans perte de contenu ou de fonctionnalité ?'},
    {crit:'Critère 10.13.', detail:' Dans chaque page web, les contenus additionnels apparaissant à la prise de focus ou au survol d’un composant d’interface sont-ils contrôlables par l’utilisateur ?'},
    {crit:'Critère 10.14.', detail:' Dans chaque page web, les contenus additionnels apparaissant via les styles CSS uniquement peuvent-ils être rendus visibles au clavier et par tout dispositif de pointage ?'},
    {crit:'Critère 11.1.', detail:' Chaque champ de formulaire a-t-il une étiquette ?'},
    {crit:'Critère 11.10.', detail:' Dans chaque formulaire, le contrôle de saisie est-il utilisé de manière pertinente ?'},
    {crit:'Critère 12.7.', detail:' Dans chaque page web, un lien d’évitement ou d’accès rapide à la zone de contenu principal est-il présent ?'},
    {crit:'Critère 13.1.', detail:' Pour chaque page web, l’utilisateur a-t-il le contrôle de chaque limite de temps modifiant le contenu ?'},
    {crit:'Critère 13.2.', detail:' Dans chaque page web, l’ouverture d’une nouvelle fenêtre ne doit pas être déclenchée sans action de l’utilisateur. Cette règle est-elle respectée ?'},
    {crit:'Critère 13.3.', detail:' Dans chaque page web, chaque document bureautique en téléchargement possède-t-il, si nécessaire, une version accessible ?'},
  ]

  // Page table
  public page : Ipage[] = [
    {page: 'Accueil exploitant', url:this.currentUrl + '#/exploitant'},
    {page: 'Accueil demandeur', url:this.currentUrl + '#/'},
    {page: 'Indicateurs', url:this.currentUrl + '#/indicators'},
    {page: 'Formulaire de création demande', url:this.currentUrl + '#/demands/new'},
    {page: 'Consultation de demande', url:this.currentUrl + '#/demands/16134'},
    {page: 'Préférences de notifications', url:this.currentUrl + '#/preferences-notification'},
    {page: 'Mes notifications', url:this.currentUrl + '#/notification'},
    {page: 'Accessibilité numérique', url:this.currentUrl + '#/accessibilite'},
  ]
  public displayedColumns: string[] = ['page', 'url'];

  constructor() {}

  ngOnInit(): void {
  }

  scrollToBottom() {
    window.scroll({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'instant'
    });
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }
}
