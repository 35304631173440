import { Component, OnInit, EventEmitter } from '@angular/core';
import { Demand } from 'src/app/shared/models/demand';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/shared/models/user';
import { Subscription } from 'rxjs';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { environment } from '@env/environment';
import { AjoutUrlModalComponent } from '@app/components/modals/ajout-url-modal/ajout-url-modal.component';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { DemandService } from '@app/shared/services/demand/demand.service';
import Toast from '@app/shared/helpers/toast';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { TextInputDialogComponent } from '@app/components/modals/text-input-dialog-modal/text-input-dialog.component';
import { Title } from '@angular/platform-browser';
import { BreakpointObserver } from '@angular/cdk/layout';


@Component({
  selector: 'app-demand',
  templateUrl: './demand.component.html',
  styleUrls: ['./demand.component.scss'],
})
export class DemandComponent implements OnInit {
  public demand: Demand;
  user: User;
  public userMode = getUserMode();
  previousDemand;
  nextDemand;
  isUserAuthorizedForReopenDemand: boolean = false;
  isReopenRequested = false;
  isRequestSent = false;
  public reloadHistory: EventEmitter<any> = new EventEmitter<any>();

  status_for_adding_attachments: any[] = environment.status_for_adding_attachments;
  toggle_feature_navigation_demands = environment.toggle_feature_navigation_demands;
  toggle_feature_carto_atlas = environment.toggle_feature_carto_atlas;

  paGotAttachment: boolean = false;

  isSmallScreen: boolean = false;
  isLoading: boolean = false;

  private subscription: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private demandService: DemandService,
    private titleService: Title,
    public Dialog: MatDialog,
    private authService: AuthenticationService,
    private breakPoint: BreakpointObserver,
  ) { }
  checkIfUserIsAuthorizedForReopenDemand() {
    const userEmail = this.authService.getCurrentUserEmail();
    this.isUserAuthorizedForReopenDemand = environment.authorizedEmailsForReopenDemand.includes(userEmail);
  }
  ngOnInit() {
    const maxWidth = '(max-width: 991px)';
    const minWidth = '(min-width: 992px)';
    this.isSmallScreen = this.breakPoint.isMatched(maxWidth);
    this.breakPoint.observe([maxWidth, minWidth])
      .subscribe((result) => {
        this.isSmallScreen = result.breakpoints[maxWidth];
      });
    this.checkIfUserIsAuthorizedForReopenDemand();
    this.route.params.subscribe(params => {
      this.demand = this.route.snapshot.data.demand;
      this.user = this.route.snapshot.data.user;

      if (this.demand) {
        this.previousDemand = this.demandService.getPreviousDemand(this.demand.id);
        this.nextDemand = this.demandService.getNextDemand(this.demand.id);

        if (params.attachementId !== undefined) {
          let storage_full_uri = 'demands/' + this.demand.id + '/attachments/download/' + params.attachementId + '/';
          this.demandService.getDownloadUrl(storage_full_uri).subscribe((res: any) => {
            window.location.replace(res.url);
          });
        }
        if (this.demand.is_acknowledge === false) {
          this.demandService.acknowledgeDemand(this.demand.id);
        }

        for (let y = 0; y < this.demand.point_avancement.length; y++) {
          if (this.demand.point_avancement[y].attachments.length > 0) {
            this.paGotAttachment = true;
          }
        }
        this.titleService.setTitle("Demande n°" + this.demand.id + " - Contrat " + this.demand.contract + " - " + environment.app_name_short.toUpperCase())
        this.isLoading = false;
      }
    });

    this.subscription = this.demandService.getDemandUpdated().subscribe((demand) => {
      this.demand = demand;
    });

    this.reloadHistory.asObservable().subscribe((res) => {
      this.reloadResolve();
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  hasPermission() {
    return this.user && this.user.super_user === true ? this.demand.created_by.id === this.user.id : true;
  }

  isAddAttachementEnable() {
    return demandMatchState(this.demand, this.status_for_adding_attachments);
  }

  addUrl() {
    const dialogRef = this.dialog.open(AjoutUrlModalComponent, { disableClose: false });
    dialogRef.afterClosed().subscribe((result) => {
      for (let y = 0; y < this.demand.point_avancement.length; y++) {
        if (this.demand.point_avancement[y].attachments.find((e) => e.file_name === result.description)) {
          Toast.error('Un lien portant le même nom figure déjà parmi les liens téléchargés', 'Lien refusé');
          return
        } else if (this.demand.point_avancement[y].attachments.find((e) => e.storage_full_uri === result.url)) {
          Toast.error('Ce lien figure déjà parmi les liens téléchargés (avec un nom différent)', 'Lien refusé');
          return
        }
      }
      for (let i = 0; i < this.demand.attachments.length; i++) {
        if (this.demand.attachments.find((e) => e.file_name === result.description)) {
          Toast.error('Un lien portant le même nom figure déjà parmi les liens téléchargés', 'Lien refusé');
          return;
        } else if (this.demand.attachments.find((e) => e.storage_full_uri === result.url)) {
          Toast.error('Ce lien figure déjà parmi les liens téléchargés (avec un nom différent)', 'Lien refusé');
          return;
        }
      }

      if (result.status === true) {
        let files = [];
        files.push({
          file_name: result.description,
          _url: result.url,
          id: null,
          mode_creation: this.userMode.toUpperCase(),
        });

        this.demandService.createAttachments(this.demand, files, 'demand', undefined, "true").subscribe((result) => {
          this.reloadResolve();
          Toast.info('Le lien joint a été ajouté à cette demande.', 'Lien ajouté');
        });
      }
    });
  }

  goToPreviousDemand() {
    if (this.previousDemand)
      this.isLoading = true;
    this.demandService.openDemandDetail(this.previousDemand);
  }

  goToNextDemand() {
    if (this.nextDemand)
      this.isLoading = true;
    this.demandService.openDemandDetail(this.nextDemand);
  }

  allDownload() {
    this.demandService.allAttachmentDownload(this.demand);
  }

  reloadResolve() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]).then(() => {
      this.demand = this.route.snapshot.data.demand;
      this.ngOnInit();
    });
  }

  reopenDemand(result): void {
    this.demandService.reopenDemand(this.demand.id, result).subscribe({
      next: (response) => {
      },
      error: (error) => {

        console.error('Erreur lors de la réouverture de la demande', error);
      }

    });
    this.isReopenRequested = true;
  }

  reloadPage() {
    window.location.reload();
  }
  openDialog(): void {
    const dialogRef = this.Dialog.open(TextInputDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.reopenDemand(result);
      this.reloadPage();
    });
  }

  goToAtlas() {
    this.demandService.goToAtlas(this.demand);
  }
}
