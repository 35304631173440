import { Component, Input, OnInit, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { IMessage } from 'src/app/shared/models/message';
import { GoogleMap } from '@angular/google-maps';
import * as _ from 'lodash';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import {  getDemandStatusLabel, getLastPointAvancement, getLastTransitionAttachments, getLastTransitionMessage } from '@app/shared/helpers/demand-helper';
import { IAttachment } from '@app/shared/models/attachment';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { User, UserType } from '@app/shared/models/user';

declare var google: any;

interface ILocation {
  lat: string;
  lng: string;
  zoom: number;
  geometry: any;
}

@Component({
  selector: 'app-demand-tab-print',
  templateUrl: './demand-tab-print.component.html',
  styleUrls: ['./demand-tab-print.component.scss'],
})
export class DemandTabPrintComponent implements OnInit {
  @Input() demand: Demand;
  @Input() isPdf = false;
  @Output() mapEvent : EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(GoogleMap) map: GoogleMap;
  @ViewChild('int_comment') internal_comment;
  @ViewChild('int_ref') internal_reference;
  public location: {
    lat: number;
    lng: number;
    zoom: number;
  } = {
      lat: null,
      lng: null,
      zoom: 17,
    };
  public mode: any = null;
  pointAvancementMessage;
  mapsUrl;
  displayMessage: IMessage;
  displayAttachments: IAttachment[];
  isSmallScreen: boolean;
  toggle_feature_internal_comment = environment.toggle_feature_internal_comment;
  toggle_feature_send_ilotier = environment.toggle_feature_send_ilotier;
  toggle_feature_internal_reference = environment.toggle_feature_internal_reference;
  activity_label = environment.activity_label;
  client_name = environment.client_name;
  getDemandStatusLabel = getDemandStatusLabel;
  urba_motif_value: string;

  sub: Subscription;

  user: User;

  // Document d'urbanisme
  public showBlockUrba: boolean = false;

  // carto
  zoom: number = environment.map_zoom;
  center: google.maps.LatLngLiteral = { lat: environment.map_latitude, lng: environment.map_longitude }
  markerOption: google.maps.MarkerOptions = { draggable: false }
  markerPosition: google.maps.LatLngLiteral | undefined
  styleOff: google.maps.MapTypeStyle[] = [{ featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }]}, { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }]}];
  styleOn: google.maps.MapTypeStyle[] = [{ featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'on' }]}, { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'on' }]}];
  mapOptions: google.maps.MapOptions =  {
    fullscreenControl: false,
    streetViewControl: false,
    styles: this.styleOn
  }
    

  constructor(
    private authService: AuthenticationService
  ) {

    this.authService.getUser().then((_user) => {
      this.user = _user as User;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.demand) {
      this.ngOnInit();
    }
  }

  ngOnInit() {
    this.mode = getUserMode();
    this.showBlockUrba = this.demand.demand_object.code === 'DOC_URBA';
    this.urba_motif_value = this.demand.urba_motif === 'Autre' ? 'Autre : ' + this.demand.urba_motif_bis : this.demand.urba_motif;

    if (this.demand.location_latitude && this.demand.location_longitude) {
      const position = { lat: parseFloat(this.demand.location_latitude), lng: parseFloat(this.demand.location_longitude) }
      this.markerPosition = position
      this.center = position
      this.zoom = 17
    }
    
    this.getDisplayedMessage();
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  getDisplayedMessage() {
    this.displayMessage = undefined;

    // We get the last PA's message from last transition
    const lastPointAvancement = getLastPointAvancement(this.demand);
    // We get the last transition's message from EXPLOITANT (here is DEMANDEUR view so we display messages from EXPLOITANT)
    const lastTransition = getLastTransitionMessage(this.demand, UserType.EXPLOITANT);
    if (lastTransition && lastPointAvancement) {
      if (Date.parse(lastTransition.created_at) > Date.parse(lastPointAvancement.created_at)) {
        this.displayMessage = this.formatMessage(lastTransition, true)
        this.displayAttachments = getLastTransitionAttachments(this.demand)
      } else if (lastPointAvancement.mode === UserType.EXPLOITANT.toUpperCase()) {
        // here is DEMANDEUR view so we display messages from EXPLOITANT
        this.displayMessage = this.formatMessage(lastPointAvancement)
        this.displayAttachments = lastPointAvancement.attachments
      }
    } else if (lastTransition) {
      this.displayMessage = this.formatMessage(lastTransition, true)
      this.displayAttachments = getLastTransitionAttachments(this.demand)
    } else if (lastPointAvancement && lastPointAvancement.mode === UserType.EXPLOITANT.toUpperCase()) {
      // here is DEMANDEUR view so we display messages from EXPLOITANT
      this.displayMessage = this.formatMessage(lastPointAvancement)
      this.displayAttachments = lastPointAvancement.attachments
    }
  }

  formatMessage(message: any, transition: boolean = false) {
    return {
      user: transition ? message.user : message.created_by,
      message: message.message,
      created_at: message.created_at,
    }
  }

  getColCssClass() {
    return this.displayAttachments !== undefined && this.displayAttachments.length !== 0 ? 'col-xs-12 col-md-6' : 'col-xs-12 col-md-12';
  }

  _emitMapReady() {
    console.log("emit")
    setTimeout(() => {
      this.mapEvent.emit(true)
    }, 1000)

  }
}
