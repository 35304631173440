import { Component, Input, OnInit } from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { getUserMode, userIsDemandeur } from '@app/shared/helpers/user-modes-helper';
import { getDemandStatusLabel } from '@app/shared/helpers/demand-helper';
import { BreakpointObserver } from '@angular/cdk/layout';
import { environment } from '@env/environment';
@Component({
  selector: 'app-demand-status-info',
  templateUrl: './demand-status-info.component.html',
  styleUrls: ['./demand-status-info.component.scss'],
})
export class DemandStatusInfoComponent implements OnInit {
  @Input() demand: Demand;
  @Input() showDate: boolean = true;

  isSmallScreen : boolean = false;
  constructor(
    private breakPoint: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    const maxWidth = '(max-width: 991px)';
    const minWidth = '(min-width: 992px)';
    this.isSmallScreen = this.breakPoint.isMatched(maxWidth);
    this.breakPoint.observe([maxWidth, minWidth])
      .subscribe((result) => {
        this.isSmallScreen = result.breakpoints[maxWidth];
    });
  }

  getDemandStatusLabel = getDemandStatusLabel;

    public setIdColor(): string {
      return environment.status_colors[this.demand.workflow_current_state.code]
        ? environment.status_colors[this.demand.workflow_current_state.code]
        : 'inherit';
    }

  public getStatusCssClass(): string {
    console.log(`dmd-status--${getUserMode()}_${this.demand.workflow_current_state.code.slice(8).toLowerCase()}`)
    console.log(this.demand.workflow_current_state)
    return `dmd-status--${getUserMode()}_${this.demand.workflow_current_state.code.slice(8).toLowerCase()}`;
  }

  public getDateCssClass(): string {
    if(userIsDemandeur()) return `date`;
    return '';
  }

  get getLastTransitionDate(): string {
    let lastDate: string = ""
    this.demand.transitions.forEach((transition) => {
      if(lastDate == "") lastDate = transition['created_at'];
      if(new Date(transition['created_at']) > new Date(lastDate)) {
        lastDate = transition['created_at'];
      }
    })
    return lastDate
  }
}
