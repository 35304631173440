<div style="padding-top:10px;">
  <ul aria-label="Fichiers et Liens" style="padding-left:0;">
    <li *ngFor="let file of _files;" style="list-style-type: none; display: flex;">
      <p mat-line class="cut-text">{{ file.file_name }}</p>
      <button *ngIf="!readonly && ((canRemoveFile && (userMode === 'demandeur')) || (canRemoveFile && userMode === 'exploitant' && (file.created_by == user.email)))" style="cursor: pointer; margin-right: 5px;" [disabled]="file.mode_creation?.toUpperCase()!=userMode.toUpperCase()"
      (click)='removeFile(file)' attr.aria-label="Supprimer {{file.file_name}}" class="btn-clear clickable"><mat-icon>clear</mat-icon></button>
    </li>
  </ul>
</div>
<div>
    <button *ngIf="!readonly && isFileEnabled" (click)="fileInput.click()" mat-raised-button
      class="full-width-btn-mobile uppercased upload-btn upload-file-btn mat-primary">
      <div style="display: flex;">
        <mat-icon class="mat-18 attach-icon">attach_file</mat-icon>
        Ajouter un fichier
      </div>
    </button>
    <button 
        *ngIf="isFileEnabled"
        matTooltip="Les fichiers autorisés sont de type texte, tableur, pdf, image, vidéo, audio et archive, à l’exception des scripts et programmes. La taille maximale par fichier est de {{max_file_size / 1000 / 1000}} Mo."
        class="clickable btn-info_add_file"
      >
        <mat-icon>info</mat-icon>
    </button>
  <input
    #fileInput id="upload-file" class="v-btn" type="file"
    (change)="onFileInput($event.srcElement.files);$event.target.value = null;" placeholder="Upload a file..."
    accept=".odt,.ods,.odp,.doc,.docx,.dat,.csv,.rtf,.txt,.xml,.html,.ppt,.pptx,.xls,.xlsx,.sld,.sldx,.zip,.rar,.gz,.7z,.ace,.tar,.bz2,.pdf,.sxw,.wps,audio/*,video/*,image/*"
    multiple />
  <button (click)="addUrl()" mat-raised-button class="full-width-btn-mobile uppercased upload-btn upload-btn-sm mat-primary"
    *ngIf="!readonly && toggle_feature_attachements_url && isLinkEnabled">
    <div style="display: flex;">
      <mat-icon class="mat-18" style="margin-right: 4px">link</mat-icon>
      Ajouter un lien
    </div>
  </button>
</div>