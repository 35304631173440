import { Component } from "@angular/core";
import { environment } from "@env/environment";
const { version: appVersion } = require("../../../../../package.json");

@Component({
  selector: "app-about-modal",
  templateUrl: "./about-modal.component.html",
  styleUrls: ["./about-modal.component.scss"],
})
export class AboutModalComponent {
  public fiche_memo_link = environment.fiche_memo_link;
  public appVersion;
  constructor() {
    this.appVersion = appVersion;
  }
}
